<div class="min-h-full">
  <sim-top-menu
    [sideMenuOpen]="sideMenu.isOpen"
    (toggleSideMenu)="sideMenu.toggleSideMenu()"
  ></sim-top-menu>
  <sim-side-menu #sideMenu>
    <div class="max-w-7xl mx-auto pt-2 sm:pt-0">
      <router-outlet></router-outlet>
    </div>
  </sim-side-menu>
</div>
