import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { Notification } from './notification.model';

@Injectable({ providedIn: 'root' })
export class NotificationsResource {
  constructor(private httpClient: HttpClient) {}

  getAll() {
    return this.httpClient.get<Notification[]>(
      `${environment.apiUrl}/Notification/GetAll`
    );
  }

  markAsRead(notificationsIds: number[]) {
    return this.httpClient.put(
      `${environment.apiUrl}/Notification/MarkAsRead`,
      notificationsIds
    );
  }

  markAllAsRead() {
    return this.httpClient.put(`${environment.apiUrl}/Notification/MarkAllAsRead`, null);
  }
}
