import { Component, ChangeDetectionStrategy, Input } from '@angular/core';

import { Notification, NotificationType } from '@resources/notifications';

@Component({
  selector: 'sim-notification',
  templateUrl: './notification.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NotificationComponent {
  route!: string[];
  title!: string;
  description!: string;
  pictureId!: string;

  @Input() set notification(value: Notification) {
    const { route, title, description } = this.compose(value);
    this.route = route;
    this.description = description;
    this.title = title;
    this.pictureId = value.pictureId!;
  }

  private compose(notification: Notification): {
    title: string;
    route: string[];
    description: string;
  } {
    const { postId, wallId, eventId, projectId } = notification.sourceIds;

    switch (notification.type) {
      case NotificationType.NewEvent:
        return {
          title: notification.title,
          description: $localize`New event created`,
          route: ['/events', eventId!.toString()],
        };
      case NotificationType.NewWall:
        return {
          title: notification.title,
          description: $localize`New wall created`,
          route: ['/wall', wallId!.toString()],
        };
      case NotificationType.WallPost:
        return {
          title: notification.title,
          description: $localize`New post in ${notification.description}:wallName: wall`,
          route: ['/wall', wallId!.toString(), postId!.toString()],
        };
      case NotificationType.EventPost:
        return {
          title: notification.title,
          description: $localize`New post in ${notification.description}:eventName: event`,
          route: ['/events', eventId!.toString(), postId!.toString()],
        };
      case NotificationType.ProjectPost:
        return {
          title: notification.title,
          description: $localize`New post in ${notification.description}:projectName: project`,
          route: ['/projects', projectId!.toString(), postId!.toString()],
        };
      case NotificationType.WallComment:
        return {
          title: this.normalizeTitle(notification),
          description: $localize`Commented your post`,
          route: ['/wall', wallId!.toString(), postId!.toString()],
        };
      case NotificationType.EventComment:
        return {
          title: this.normalizeTitle(notification),
          description: $localize`Commented your post`,
          route: ['/events', eventId!.toString(), postId!.toString()],
        };
      case NotificationType.ProjectComment:
        return {
          title: this.normalizeTitle(notification),
          description: $localize`Commented your post`,
          route: ['/projects', projectId!.toString(), postId!.toString()],
        };
      case NotificationType.FollowingComment:
        let route: string[] = [];
        if (wallId !== null) {
          route = ['/wall', wallId.toString(), postId!.toString()];
        } else if (eventId !== null) {
          route = ['/events', eventId.toString(), postId!.toString()];
        } else if (projectId !== null) {
          route = ['/projects', projectId.toString(), postId!.toString()];
        }
        return {
          title: this.normalizeTitle(notification),
          description: $localize`Commented the post you are following`,
          route,
        };
      case NotificationType.EventReminder:
        return {
          title: $localize`Reminder`,
          description: $localize`Join ${notification.description}:eventName: event this week`,
          route: ['/events'],
        };
      default:
        throw new Error(`Unsupported notification type ${notification.type}`);
    }
  }

  private normalizeTitle({ others, title }: Notification) {
    if (others > 0) {
      return title + $localize` and ${others}:othersCount: others`;
    }
    return title;
  }
}
