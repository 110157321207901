import { Component, EventEmitter, Input, Output } from '@angular/core';
import { NavigationService } from '@core/navigation';
import { UserService } from '@core/user';

@Component({
  selector: 'sim-top-menu',
  templateUrl: './top-menu.component.html',
  styleUrls: ['./top-menu.component.scss'],
})
export class TopMenuComponent {
  @Output() toggleSideMenu: EventEmitter<void>;

  @Input() sideMenuOpen = false;

  constructor(public navigation: NavigationService, public userContext: UserService) {
    this.toggleSideMenu = new EventEmitter<void>();
  }

  triggerSideMenuToggle(): void {
    this.toggleSideMenu.emit();
  }
}
