import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, tap } from 'rxjs';
import { AppConfigModel } from './app-config.model';

@Injectable({ providedIn: 'root' })
export class AppConfigService {
  config!: AppConfigModel;

  constructor(private httpClient: HttpClient) {}

  loadAppConfig(): Observable<AppConfigModel> {
    return this.httpClient
      .get<AppConfigModel>('/assets/config.json')
      .pipe(tap(appConfigModel => (this.config = appConfigModel)));
  }
}
