<ng-container *ngIf="store.vm$ | async as vm">
  <button
    type="button"
    class="relative bg-white p-1 rounded-full text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
    [matMenuTriggerFor]="menu"
  >
    <span class="sr-only" i18n>View notifications</span>
    <mat-icon class="block h-6 w-6" [svgIcon]="'heroicons_outline:bell'"></mat-icon>
    <span *ngIf="vm.notifications.length > 0" class="absolute top-1 right-1 flex h-2 w-2">
      <span
        class="animate-ping absolute inline-flex h-full w-full rounded-full bg-red-400 opacity-75"
      ></span>
      <span class="relative inline-flex rounded-full h-2 w-2 bg-red-500"></span>
    </span>
  </button>

  <mat-menu #menu="matMenu" class="mat-menu-flat text-sm max-w-xs sm:ml-0 ml-6">
    <div (click)="$event.stopPropagation()">
      <!-- Skeleton -->
      <div
        *ngIf="vm.loading"
        role="alert"
        class="animate-pulse space-y-2 p-4"
        aria-busy="true"
        aria-label="loading notifications"
        i18n-aria-label
      >
        <div class="h-4 bg-gray-300 rounded"></div>
        <div class="h-4 bg-gray-300 rounded w-5/6"></div>
      </div>

      <!-- Actual notifications -->
      <div *ngIf="!vm.loading" class="w-80">
        <div class="flex items-center bg-indigo-500 p-4 text-white font-medium">
          <h2 class="flex-1 text-md" i18n
            >Notifications ({{ vm.notifications.length }})</h2
          >

          <button type="button" mat-icon-button class="text-white">
            <mat-icon
              [svgIcon]="'heroicons_solid:cog'"
              matTooltip="notification settings"
              i18n-matTooltip
              class="h-5 w-5"
            ></mat-icon>
          </button>

          <button
            type="button"
            mat-icon-button
            class="text-white"
            (click)="store.markAllAsRead()"
            [disabled]="vm.notifications.length === 0"
            aria-label="mark all as read"
            i18n-aria-label
          >
            <mat-icon
              class="h-5 w-5"
              [svgIcon]="'heroicons_solid:mail-open'"
              matTooltip="mark all as read"
              [matTooltipDisabled]="vm.notifications.length === 0"
              i18n-matTooltip
            ></mat-icon>
          </button>
        </div>

        <div *ngIf="vm.notifications.length === 0" class="p-5 text-gray-400" i18n
          >You're all caught up!</div
        >

        <ul
          *ngIf="vm.notifications.length > 0"
          role="list"
          class="divide-y divide-gray-200 overflow-y-scroll max-h-96"
        >
          <li
            *ngFor="let notification of vm.notifications"
            class="py-4 flex items-center"
          >
            <sim-notification
              class="flex-auto"
              [notification]="notification"
              (click)="store.markAsRead(notification)"
            >
            </sim-notification>

            <button
              type="button"
              mat-icon-button
              class="text-gray-500 ml-4"
              (click)="store.markAsRead(notification)"
            >
              <mat-icon
                [svgIcon]="'heroicons_outline:x'"
                class="h-5 w-5"
                matTooltip="mark as read"
                i18n-matTooltip
              >
              </mat-icon>
              <span class="sr-only" i18n>mark as read</span>
            </button>
          </li>
        </ul>
      </div>
    </div>
  </mat-menu>
</ng-container>
