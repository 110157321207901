<nav class="bg-white shadow-sm sticky top-0 z-50">
  <div class="max-w-7xl mx-auto px-4 sm:px-6 lg:px-6">
    <div class="flex justify-between h-16">
      <div class="flex">
        <div class="mr-2 flex items-center sm:hidden">
          <!-- Side menu button -->
          <button
            type="button"
            (click)="triggerSideMenuToggle()"
            id="side-menu-toggle"
            class="menu-toggle-button"
            [ngClass]="{ 'menu-toggle-button-pressed': sideMenuOpen }"
          >
            <span class="sr-only" i18n>Open main menu</span>
            <mat-icon
              class="menu-toggle-icon"
              [class.hidden]="sideMenuOpen"
              [svgIcon]="'heroicons_outline:menu'"
            >
            </mat-icon>
            <mat-icon
              class="menu-toggle-icon"
              [class.hidden]="!sideMenuOpen"
              [svgIcon]="'heroicons_outline:x'"
            >
            </mat-icon>
          </button>
        </div>

        <div class="flex-shrink-0 flex items-center">
          <a class="text-indigo-600 font-bold" routerLink="/wall">SIMOONA</a>
        </div>
        <div class="hidden sm:ml-6 sm:flex sm:space-x-8">
          <ng-container
            *ngFor="let menuItem of navigation.menuItems$ | async"
            [ngSwitch]="menuItem.type"
          >
            <ng-container *ngSwitchCase="'group'">
              <a
                href="javascript:void(0)"
                class="menu-item"
                [matMenuTriggerFor]="childrenMenu"
              >
                {{ menuItem.title }}
              </a>

              <mat-menu
                #childrenMenu="matMenu"
                backdropClass="cdk-overlay-transparent-backdrop"
              >
                <ng-container
                  *ngFor="let childMenuItem of menuItem.children"
                  [ngSwitch]="childMenuItem.type"
                >
                  <!-- Only external links have initialized type -->
                  <a
                    *ngSwitchCase="'link'"
                    href="{{ childMenuItem.link }}"
                    routerLinkActive="active"
                    mat-menu-item
                  >
                    {{ childMenuItem.title }}
                  </a>

                  <a
                    *ngSwitchDefault
                    [routerLink]="childMenuItem.link"
                    routerLinkActive="active"
                    mat-menu-item
                  >
                    {{ childMenuItem.title }}
                  </a>
                </ng-container>
              </mat-menu>
            </ng-container>
          </ng-container>
        </div>
      </div>

      <div class="space-x-3 ml-6 flex items-center">
        <sim-notifications></sim-notifications>
        <sim-admin-menu></sim-admin-menu>
        <sim-user></sim-user>
      </div>
    </div>
  </div>
</nav>
