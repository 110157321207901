import { Component } from '@angular/core';
import { UserService } from 'src/app/core/user/user.service';
import { UntilDestroy } from '@ngneat/until-destroy';
import { AuthService } from '@core/auth';
import { Router } from '@angular/router';

@UntilDestroy()
@Component({
  selector: 'sim-user',
  templateUrl: './user.component.html',
})
export class UserComponent {
  constructor(
    public userService: UserService,
    private authService: AuthService,
    private router: Router
  ) {}

  signOut(): void {
    this.authService.logout().subscribe(() => {
      this.router.navigate(['login']);
    });
  }
}
