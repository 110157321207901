import { Component } from '@angular/core';
import { Layout } from 'src/app/layout/layout.types';

@Component({
  selector: 'sim-layout',
  templateUrl: './layout.component.html',
})
export class LayoutComponent {
  layout: Layout = 'vertical';
}
