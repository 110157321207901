<ng-container *ngIf="userService.user$ | async as user">
  <button
    class="bg-white flex text-sm rounded-full focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
    [matMenuTriggerFor]="userMenu"
  >
    <span class="sr-only">Open user menu</span>

    <sim-user-avatar [user]="user"></sim-user-avatar>
  </button>

  <mat-menu [xPosition]="'before'" #userMenu="matMenu">
    <button mat-menu-item>
      <span class="flex flex-col leading-none">
        <span>Signed in as</span>
        <span class="mt-1.5 text-md font-medium">{{ user.fullName }}</span>
      </span>
    </button>
    <mat-divider class="my-2"></mat-divider>
    <button mat-menu-item>
      <mat-icon [svgIcon]="'heroicons_outline:user-circle'"></mat-icon>
      <span>Profile</span>
    </button>
    <button mat-menu-item>
      <mat-icon [svgIcon]="'heroicons_outline:cog'"></mat-icon>
      <span>Settings</span>
    </button>
    <button mat-menu-item (click)="signOut()">
      <mat-icon [svgIcon]="'heroicons_outline:logout'"></mat-icon>
      <span>Sign out</span>
    </button>
  </mat-menu>
</ng-container>
