import { Injectable } from '@angular/core';
import { NotificationsResource, Notification } from '@resources/notifications';
import { ComponentStore, tapResponse } from '@ngrx/component-store';
import { Observable } from 'rxjs';
import { switchMap, tap, mergeMap, map, exhaustMap } from 'rxjs/operators';

export interface NotificationsState {
  notifications: Notification[];
  loading: boolean;
}

@Injectable()
export class NotificationsStore extends ComponentStore<NotificationsState> {
  readonly loading$ = this.select(state => state.loading);
  readonly notifications$ = this.select(state => state.notifications);

  readonly vm$ = this.select(
    this.loading$,
    this.notifications$,
    (loading, notifications) => ({
      loading,
      notifications,
    })
  );

  constructor(private notificationsResource: NotificationsResource) {
    super({
      notifications: [],
      loading: false,
    });

    this.load();
  }

  readonly load = this.effect(stream$ =>
    stream$.pipe(
      tap(() => this.patchState({ loading: true })),
      switchMap(() =>
        this.notificationsResource.getAll().pipe(
          tapResponse(
            notifications => this.patchState({ notifications, loading: false }),
            () => {
              // TODO: https://github.com/VismaLietuva/simoona-web-app/issues/20
            }
          )
        )
      )
    )
  );

  // optimistic action
  readonly markAsRead = this.effect((notification$: Observable<Notification>) =>
    notification$.pipe(
      // find all related notification ids
      map(notification => [notification.id, ...notification.stackedIds]),
      tap(ids =>
        this.patchState(state => ({
          notifications: state.notifications.filter(n => !ids.includes(n.id)),
        }))
      ),
      mergeMap(ids =>
        this.notificationsResource.markAsRead(ids).pipe(
          tapResponse(
            () => {},
            () => {
              // TODO: https://github.com/VismaLietuva/simoona-web-app/issues/20
            }
          )
        )
      )
    )
  );

  // optimistic action
  readonly markAllAsRead = this.effect(stream$ =>
    stream$.pipe(
      tap(() => this.patchState({ notifications: [] })),
      exhaustMap(() =>
        this.notificationsResource.markAllAsRead().pipe(
          tapResponse(
            () => {},
            () => {
              // TODO: https://github.com/VismaLietuva/simoona-web-app/issues/20
            }
          )
        )
      )
    )
  );
}
