import { Component, ChangeDetectionStrategy, Input } from '@angular/core';
import { UserModel } from '@resources/models/user';

@Component({
  selector: 'sim-user-avatar',
  templateUrl: './user-avatar.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UserAvatarComponent {
  @Input() user!: UserModel;
}
