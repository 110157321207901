import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { NgxPermissionsModule } from 'ngx-permissions';
import { NumberInputDirective } from './directives/number-input.directive';

@NgModule({
  declarations: [NumberInputDirective],
  imports: [FormsModule],
  exports: [CommonModule, NgxPermissionsModule, NumberInputDirective],
})
export class SharedModule {}
