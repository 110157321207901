<mat-sidenav-container [hasBackdrop]="true" class="sidenav-container">
  <mat-sidenav #sidenav mode="over" class="sidenav" [fixedInViewport]="true">
    <!-- Sidenav content -->
    <nav class="mt-5 px-2 space-y-1">
      <!-- Tree content -->
      <mat-tree [dataSource]="dataSource" [treeControl]="treeControl">
        <mat-tree-node
          *matTreeNodeDef="let node; when: hasChild"
          class="hover:bg-gray-50 group flex items-center px-2 py-2 text-base rounded-md"
        >
          <button
            class="w-full flex justify-between text-gray-600 font-medium hover:text-gray-900"
            [ngClass]="{ 'text-gray-900': treeControl.isExpanded(node) }"
            matTreeNodeToggle
          >
            <span class="text-left">{{ node.title }}</span>
            <mat-icon
              class="icon-size-5 transform transition-transform ease-in-out duration-150 rotate-0"
              [svgIcon]="'heroicons_solid:chevron-right'"
              [ngClass]="{ 'rotate-90': treeControl.isExpanded(node) }"
            >
            </mat-icon>
          </button>
        </mat-tree-node>

        <mat-tree-node
          *matTreeNodeDef="let node"
          matTreeNodePadding
          (click)="toggleSideMenu()"
          class="wall-button"
          [ngClass]="{ 'hover-effect': node.link === selectedItemLink }"
        >
          <ng-container [ngSwitch]="node.type">
            <a *ngSwitchCase="'link'" class="w-full" href="{{ node.link }}">{{
              node.title
            }}</a>
            <a *ngSwitchDefault class="w-full" [routerLink]="node.link">{{
              node.title
            }}</a>
          </ng-container>
        </mat-tree-node>
      </mat-tree>

      <!-- Loading walls -->
      <span
        class="text-gray-600 group flex items-center px-2 py-2 text-base font-medium rounded-md"
      >
        <mat-icon
          class="h-6 w-6"
          [class.hidden]="!sidenav.opened"
          [svgIcon]="'heroicons_outline:view-grid'"
        >
        </mat-icon>
        <span class="ml-1 my-auto" i18n>Walls</span>
      </span>

      <ng-container *ngIf="vm$ | async as vm">
        <!-- My walls -->
        <button
          (click)="selectWall(wallsType.MyWalls, sidenav)"
          routerLink="/wall"
          [ngClass]="{
            'hover-effect':
              vm.loadedWall === wallsType.MyWalls &&
              (selectedItemLink === '' || selectedItemLink === '/wall')
          }"
          class="wall-button"
        >
          <span class="text-left" i18n>My Walls</span>
        </button>

        <!-- All walls -->
        <button
          (click)="selectWall(wallsType.AllWalls, sidenav)"
          routerLink="/wall"
          [ngClass]="{
            'hover-effect':
              vm.loadedWall === wallsType.AllWalls &&
              (selectedItemLink === '' || selectedItemLink === '/wall')
          }"
          class="wall-button"
        >
          <span class="text-left" i18n>All Walls</span>
        </button>

        <!-- Other walls -->
        <button
          (click)="selectWall(wall, sidenav)"
          routerLink="/wall"
          [ngClass]="{
            'hover-effect':
              vm.loadedWall === wall &&
              (selectedItemLink === '' || selectedItemLink === '/wall')
          }"
          class="wall-button"
          *ngFor="let wall of vm.walls"
        >
          <span class="text-left">{{ wall.name }}</span>
        </button>
      </ng-container>
    </nav>
  </mat-sidenav>

  <mat-sidenav-content class="h-full">
    <ng-content></ng-content>
  </mat-sidenav-content>
</mat-sidenav-container>
