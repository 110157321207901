import { Injectable } from '@angular/core';
import { AccountResource } from '@core/resources/account';
import { TokenModel } from '@core/resources/token/token.model';
import { TokenResource } from '@core/resources/token/token.resource';
import { Observable, tap } from 'rxjs';

const ACCESS_TOKEN_KEY = 'accessToken';

@Injectable()
export class AuthService {
  constructor(
    private accountResource: AccountResource,
    private tokenResource: TokenResource
  ) {}

  set accessToken(token: string) {
    localStorage.setItem(ACCESS_TOKEN_KEY, token);
  }

  get accessToken(): string {
    return localStorage.getItem(ACCESS_TOKEN_KEY) ?? '';
  }

  get isAuthenticated(): boolean {
    return !!this.accessToken;
  }

  login(
    organization: string,
    userName: string,
    password: string
  ): Observable<TokenModel> {
    return this.tokenResource
      .getToken(organization, userName, password)
      .pipe(tap(tokenModel => (this.accessToken = tokenModel.access_token)));
  }

  logout(): Observable<void> {
    return this.accountResource.logout().pipe(tap(() => this.removeAccessToken()));
  }

  removeAccessToken(): void {
    localStorage.removeItem(ACCESS_TOKEN_KEY);
  }
}
