import { Injectable } from '@angular/core';
import { ComponentStore, tapResponse } from '@ngrx/component-store';
import { ExternalLink, ExternalLinksResource } from '@resources/external-links';
import { Observable } from 'rxjs';
import { switchMap } from 'rxjs/operators';

export interface NavigationItem {
  title: string;
  type?: 'link' | 'group';
  link?: string;
  children?: NavigationItem[];
}

export interface NavigationState {
  externalLinks: ExternalLink[];
  isLoading: boolean;
}

@Injectable({ providedIn: 'root' })
export class NavigationService extends ComponentStore<NavigationState> {
  readonly externalLinks$ = this.select(state => state.externalLinks);
  readonly isLoading$ = this.select(state => state.isLoading);

  readonly menuItems$: Observable<NavigationItem[]> = this.select(
    this.externalLinks$,
    externalLinks => [
      {
        title: $localize`Activities`,
        type: 'group',
        children: [
          { link: '/events', title: $localize`Events` },
          { link: '/kudos', title: $localize`Kudos` },
          { link: '/service-request', title: $localize`Service Request` },
          { link: '/books', title: $localize`Books` },
          { link: '/vacation', title: $localize`Vacation` },
        ],
      },
      {
        title: $localize`Company`,
        type: 'group',
        children: [
          { link: '/employees', title: $localize`Employees` },
          { link: '/office-map', title: $localize`Office map` },
          { link: '/organization', title: $localize`Organizational structure` },
          { link: '/projects', title: $localize`Projects` },
          { link: '/committees', title: $localize`Committess` },
        ],
      },
      {
        title: $localize`Externals`,
        type: 'group',
        children: externalLinks.map(link => ({
          title: link.name,
          type: 'link',
          link: link.url,
        })),
      },
    ]
  );

  constructor(private externalLinksResource: ExternalLinksResource) {
    super({
      externalLinks: [],
      isLoading: true,
    });

    this.load();
  }

  getState(): NavigationState {
    return this.get();
  }

  readonly load = this.effect(stream$ =>
    stream$.pipe(
      switchMap(() =>
        this.externalLinksResource.getAll().pipe(
          tapResponse(
            externalLinks => this.patchState({ externalLinks, isLoading: false }),
            () => {
              // TODO: https://github.com/VismaLietuva/simoona-web-app/issues/20
            }
          )
        )
      )
    )
  );

  readonly updateSelectedItem = this.updater(
    (state: NavigationState, selectedItemTitle: string | null) => {
      return {
        ...state,
        selectedItem: selectedItemTitle,
      };
    }
  );
}
