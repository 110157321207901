import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { ExternalLink } from './external-link.model';

@Injectable({ providedIn: 'root' })
export class ExternalLinksResource {
  constructor(private httpClient: HttpClient) {}

  getAll() {
    return this.httpClient.get<ExternalLink[]>(`${environment.apiUrl}/ExternalLink/List`);
  }
}
