export const enum NotificationType {
  NewEvent,
  NewWall,
  WallPost,
  EventPost,
  ProjectPost,
  WallComment,
  EventComment,
  ProjectComment,
  FollowingComment,
  EventReminder,
}

export interface Notification {
  id: number;
  title: string;
  description: string;
  type: NotificationType;
  pictureId: string | null;
  stackedIds: number[];
  others: number;
  sourceIds: {
    eventId: number | null;
    postId: number | null;
    projectId: number | null;
    wallId: number | null;
  };
}
