import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AppConfigService } from '@core/config';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { TokenModel } from './token.model';

@Injectable({ providedIn: 'root' })
export class TokenResource {
  constructor(
    private httpClient: HttpClient,
    private appConfigService: AppConfigService
  ) {}

  getToken(
    organization: string,
    userName: string,
    password: string
  ): Observable<TokenModel> {
    return this.httpClient.post<TokenModel>(
      `${environment.apiUrl}/token`,
      `grant_type=password&username=${userName}&password=${password}&client_id=${this.appConfigService.config.clientId}`,
      { headers: { organization } }
    );
  }
}
