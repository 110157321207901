export const enum ExternalLinkType {
  Basic = 1,
  Important,
}

export interface ExternalLink {
  id: number;
  name: string;
  type: ExternalLinkType;
  url: string;
}
