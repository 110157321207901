import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { WallsType } from '@resources/enums';
import {
  CreateWallModel,
  PagedWallModel,
  UpdateWallModel,
  WallListModel,
  WallMemberModel,
} from '@resources/models/wall';
import { WallPostModel } from '@resources/models/wall/posts';
import { WallFollowModel } from '@resources/models/wall/wall-follow.model';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({ providedIn: 'root' })
export class WallsResource {
  constructor(private httpClient: HttpClient) {}

  getWalls(filter: 'all' | 'followed' | 'notFollowed' = 'followed') {
    return this.httpClient.get<WallListModel[]>(`${environment.apiUrl}/wall/list`, {
      params: { filter },
    });
  }

  /** Returns wall details */
  getWall(wallId: number): Observable<WallListModel> {
    return this.httpClient.get<WallListModel>(`${environment.apiUrl}/wall/details`, {
      params: { wallId },
    });
  }

  createWall(request: CreateWallModel): Observable<{ id: string }> {
    return this.httpClient.post<{ id: string }>(
      `${environment.apiUrl}/wall/create`,
      request
    );
  }

  editWall(request: UpdateWallModel): Observable<{ id: string }> {
    return this.httpClient.put<{ id: string }>(
      `${environment.apiUrl}/wall/edit`,
      request
    );
  }

  deleteWall(wallId: number): Observable<{ id: string }> {
    return this.httpClient.delete<{ id: string }>(`${environment.apiUrl}/wall/delete`, {
      params: { wallId },
    });
  }

  follow(wallId: number, attendeeId: string): Observable<WallFollowModel> {
    return this.httpClient.put<WallFollowModel>(`${environment.apiUrl}/wall/follow`, {
      wallId,
      attendeeId,
    });
  }

  /** Return wall members list */
  getWallMembers(wallId: number): Observable<WallMemberModel[]> {
    return this.httpClient.get<WallMemberModel[]>(`${environment.apiUrl}/wall/members`, {
      params: { wallId },
    });
  }

  getWallPosts(
    wallId: number,
    page: number = 1
  ): Observable<PagedWallModel<WallPostModel>> {
    return this.httpClient.get<PagedWallModel<WallPostModel>>(
      `${environment.apiUrl}/wall/posts`,
      {
        params: { wallId, page },
      }
    );
  }

  getPosts(
    wallsType: WallsType = WallsType.MyWalls,
    page: number = 1
  ): Observable<PagedWallModel<WallPostModel>> {
    return this.httpClient.get<PagedWallModel<WallPostModel>>(
      `${environment.apiUrl}/wall/allPosts`,
      {
        params: { page, filter: wallsType },
      }
    );
  }

  searchWall(
    searchString: string,
    page: number = 1
  ): Observable<PagedWallModel<WallPostModel>> {
    return this.httpClient.get<PagedWallModel<WallPostModel>>(
      `${environment.apiUrl}/wall/search`,
      {
        params: { searchString, page },
      }
    );
  }
}
