import { Route } from '@angular/router';
import { AuthGuard, UnauthGuard } from '@core/auth';
import { InitialDataResolver } from './app.resolvers';
import { LayoutComponent } from './layout/layout.component';
import { NotFoundComponent } from './layout/shared/not-found/not-found.component';
import { eventsPaths } from './modules/events/events-paths.const';

export const appRoutes: Route[] = [
  { path: '', pathMatch: 'full', redirectTo: 'wall' },
  {
    path: '',
    component: LayoutComponent,
    data: { layout: 'vertical' },
    resolve: { initialData: InitialDataResolver },
    canActivate: [AuthGuard],
    children: [
      {
        path: 'wall',
        loadChildren: () => import('./modules/wall/wall.module').then(m => m.WallModule),
      },
      {
        path: eventsPaths.root.path,
        loadChildren: () =>
          import('./modules/events/events.module').then(m => m.EventsModule),
      },
      {
        path: 'employees',
        loadChildren: () =>
          import('./modules/employees/employees.module').then(m => m.EmployeesModule),
      },
    ],
  },
  {
    path: 'login',
    canActivate: [UnauthGuard],
    loadChildren: () => import('./modules/login/login.module').then(m => m.LoginModule),
  },
  {
    path: '**',
    pathMatch: 'full',
    loadChildren: () =>
      import('./layout/shared/not-found/not-found.module').then(m => m.NotFoundModule),
  },
];
