import { NgModule } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { SharedModule } from 'src/app/shared/shared.module';
import { LayoutComponent } from './layout.component';
import { EmptyLayoutModule } from './layouts/empty/empty.module';
import { VerticalLayoutModule } from './layouts/vertical/vertical.module';

@NgModule({
  declarations: [LayoutComponent],
  imports: [
    MatIconModule,
    MatTooltipModule,
    EmptyLayoutModule,
    VerticalLayoutModule,
    SharedModule,
  ],
  exports: [LayoutComponent, EmptyLayoutModule, VerticalLayoutModule],
})
export class LayoutModule {}
