import { CdkAccordionModule } from '@angular/cdk/accordion';
import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatDividerModule } from '@angular/material/divider';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatTreeModule } from '@angular/material/tree';
import { RouterModule } from '@angular/router';
import { UserAvatarModule } from '@features/user-avatar';
import { SharedModule } from '@shared';
import { UserModule } from 'src/app/layout/shared/user/user.module';
import { NotificationsModule } from '../../shared/notifications/notifications.module';
import { AdminMenuComponent } from './top-menu/admin-menu/admin-menu.component';
import { SideMenuComponent } from './top-menu/side-menu/side-menu.component';
import { TopMenuComponent } from './top-menu/top-menu.component';
import { VerticalLayoutComponent } from './vertical.component';

@NgModule({
  imports: [
    HttpClientModule,
    RouterModule,
    MatButtonModule,
    MatSidenavModule,
    MatDividerModule,
    MatIconModule,
    MatMenuModule,
    MatTreeModule,
    UserModule,
    SharedModule,
    NotificationsModule,
    UserAvatarModule,
    CdkAccordionModule,
  ],
  declarations: [
    VerticalLayoutComponent,
    TopMenuComponent,
    AdminMenuComponent,
    SideMenuComponent,
  ],
  exports: [VerticalLayoutComponent],
})
export class VerticalLayoutModule {}
