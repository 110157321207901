import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UserAvatarComponent } from './user-avatar.component';
import { PictureUrlModule } from '@features/picture-url';

@NgModule({
  imports: [CommonModule, PictureUrlModule],
  declarations: [UserAvatarComponent],
  exports: [UserAvatarComponent],
})
export class UserAvatarModule {}
