import { Pipe, PipeTransform } from '@angular/core';
import { UserService } from '@core/user';
import { environment } from 'src/environments/environment';

@Pipe({
  name: 'pictureUrl',
})
export class PictureUrlPipe implements PipeTransform {
  constructor(private userService: UserService) {}

  transform(
    pictureId: string | undefined | null,
    width: number,
    height: number,
    mode: 'max' | 'crop',
    defaultPictureUrl?: string
  ): string | undefined {
    const userState = this.userService.getState();

    if (pictureId?.endsWith('.gif') && userState) {
      return `${environment.apiUrl}/storage/${userState.user.organizationName}/${pictureId}`;
    }

    if (pictureId && userState) {
      return `${environment.apiUrl}/storage/${userState.user.organizationName}/${pictureId}?width=${width}&height=${height}&mode=${mode}`;
    }

    return defaultPictureUrl;
  }
}
