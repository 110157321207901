export interface UserInfo {
  userId: string;
  userName: string;
  fullName: string;
  hasRegistered: boolean;
  impersonated: boolean;
  organizationId: string;
  organizationName: string;
  timeZone: string;
  cultureCode: string;
  pictureId: string;
  roles: string[];
  permissions: string[];
}
