import { Injectable } from '@angular/core';
import { WallsType } from '@core/resources/enums';
import { WallListModel } from '@core/resources/models/wall';
import { WallsResource } from '@core/resources/walls';
import { ComponentStore, tapResponse } from '@ngrx/component-store';
import { switchMap } from 'rxjs/operators';

export interface WallSidebarState {
  walls: WallListModel[];
  loadedWall: WallsType | WallListModel;
}
@Injectable({ providedIn: 'root' })
export class WallNavigationService extends ComponentStore<WallSidebarState> {
  readonly walls$ = this.select(state => state.walls);
  readonly loadedWall$ = this.select(state => state.loadedWall);

  readonly vm$ = this.select(this.walls$, this.loadedWall$, (walls, loadedWall) => ({
    walls,
    loadedWall,
  }));

  constructor(private readonly wallsResource: WallsResource) {
    super({
      walls: [],
      loadedWall: WallsType.AllWalls,
    });

    this.init();
  }

  getState(): WallSidebarState {
    return this.get();
  }

  readonly init = this.effect(stream$ =>
    stream$.pipe(
      switchMap(() =>
        this.wallsResource.getWalls('followed').pipe(
          tapResponse(
            walls =>
              this.patchState({
                walls,
              }),
            () => {
              // TODO: https://github.com/VismaLietuva/simoona-web-app/issues/20
            }
          )
        )
      )
    )
  );

  readonly updateLoadedWall = this.updater(
    (state: WallSidebarState, loadedWall: WallsType | WallListModel) => {
      return { ...state, loadedWall: loadedWall };
    }
  );
}
