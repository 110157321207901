import { Component, ChangeDetectionStrategy } from '@angular/core';
import { NotificationsStore } from './notifications.store';

@Component({
  selector: 'sim-notifications',
  templateUrl: './notifications.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [NotificationsStore],
})
export class NotificationsComponent {
  constructor(public store: NotificationsStore) {}
}
