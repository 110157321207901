<button
  *ngxPermissionsOnly="'Admin'"
  type="button"
  class="bg-white p-1 rounded-full text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
  [matMenuTriggerFor]="adminMenu"
>
  <span class="sr-only" i18n>Admin settings</span>
  <mat-icon class="block h-6 w-6" [svgIcon]="'heroicons_outline:cog'"></mat-icon>
</button>
<mat-menu #adminMenu="matMenu">
  <button mat-menu-item i18n>Users</button>
  <button mat-menu-item i18n>Roles</button>
  <button mat-menu-item i18n>Offices</button>
  <button mat-menu-item i18n>Room types</button>
  <button mat-menu-item i18n>Kudos basket</button>
  <button mat-menu-item i18n>Customization</button>
  <button mat-menu-item i18n>Lotteries</button>
</mat-menu>
