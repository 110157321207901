import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NotificationsComponent } from './notifications.component';
import { MatIconModule } from '@angular/material/icon';
import { OverlayModule } from '@angular/cdk/overlay';
import { MatButtonModule } from '@angular/material/button';
import { A11yModule } from '@angular/cdk/a11y';
import { NotificationComponent } from './notification/notification.component';
import { RouterModule } from '@angular/router';
import { MatTooltipModule } from '@angular/material/tooltip';
import { PictureUrlModule } from '@features/picture-url';
import { MatMenuModule } from '@angular/material/menu';

@NgModule({
  imports: [
    CommonModule,
    MatIconModule,
    OverlayModule,
    MatButtonModule,
    A11yModule,
    RouterModule,
    MatTooltipModule,
    PictureUrlModule,
    MatMenuModule,
  ],
  declarations: [NotificationsComponent, NotificationComponent],
  exports: [NotificationsComponent],
})
export class NotificationsModule {}
