<a [routerLink]="route" class="block ml-4 flex items-center">
  <img
    class="w-10 h-10 rounded-full object-cover mr-4"
    [src]="pictureId | pictureUrl: 45:45:'crop'"
    alt=""
  />
  <div>
    <div class="font-medium text-gray-900">{{ title }}</div>
    <div class="text-gray-500">{{ description }}</div>
  </div>
</a>
