import { Injectable } from '@angular/core';
import { Resolve } from '@angular/router';
import { forkJoin, Observable } from 'rxjs';
import { UserService } from '@core/user';

@Injectable({ providedIn: 'root' })
export class InitialDataResolver implements Resolve<any> {
  constructor(private userService: UserService) {}

  resolve(): Observable<any> {
    this.userService.load();

    // Fork join multiple API endpoint calls to wait all of them to finish
    return this.userService.loaded$;
  }
}
