import { Directive, HostListener, Input } from '@angular/core';

@Directive({
  selector: '[simNumberInput]',
})
export class NumberInputDirective {
  @Input() allowFloat = false;
  @Input() allowPlusSign = false;

  // tslint:disable: deprecation
  @HostListener('keydown', ['$event'])
  onKeyDown(e: KeyboardEvent): void {
    // Allow: Delete, Backspace, Tab, Escape, Enter, End, Home, Left, Right, Space
    if (
      [
        'Delete',
        'Backspace',
        'Tab',
        'Escape',
        'Enter',
        'End',
        'Home',
        'ArrowLeft',
        'ArrowRight',
        ' ',
      ].includes(e.key)
    ) {
      return;
    }
    // Allow: Ctrl(meta) + A, C, V, X
    if ((e.ctrlKey || e.metaKey) && ['a', 'c', 'v', 'x'].includes(e.key)) {
      return;
    }
    // Allow: numbers
    if (e.key >= '0' && e.key <= '9') {
      return;
    }
    // allow floating point separators if needed
    if (this.allowFloat && (e.key === '.' || e.key === ',')) {
      return;
    }

    if (
      (this.allowPlusSign && e.key === '+') ||
      ((e.ctrlKey || e.metaKey) && e.key === '+')
    ) {
      return;
    }
    // prevent other
    e.preventDefault();
  }
}
