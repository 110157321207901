import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { UserInfo } from './account.model';

@Injectable({ providedIn: 'root' })
export class AccountResource {
  constructor(private httpClient: HttpClient) {}

  getUserInfo(): Observable<UserInfo> {
    return this.httpClient.get<UserInfo>(`${environment.apiUrl}/Account/UserInfo`);
  }

  logout(): Observable<void> {
    return this.httpClient.post<void>(`${environment.apiUrl}/Account/Logout`, undefined);
  }
}
