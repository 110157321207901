import { Injectable } from '@angular/core';
import { ComponentStore, tapResponse } from '@ngrx/component-store';
import { AccountResource, UserInfo } from '@resources/account';
import { NgxPermissionsService } from 'ngx-permissions';
import { filter, map, switchMap, take } from 'rxjs/operators';

export interface UserState {
  user: UserInfo;
}

@Injectable({ providedIn: 'root' })
export class UserService extends ComponentStore<UserState> {
  readonly user$ = this.select(state => state.user);
  readonly loaded$ = this.user$.pipe(
    filter(user => user !== null),
    take(1)
  );

  constructor(
    private accountResource: AccountResource,
    private permissionsService: NgxPermissionsService
  ) {
    super();
  }

  getState() {
    return this.get();
  }

  /**
   * Effects
   */
  readonly load = this.effect(stream$ =>
    stream$.pipe(
      switchMap(() =>
        this.accountResource.getUserInfo().pipe(
          tapResponse(
            user => {
              this.setState({ user });
              this.permissionsService.loadPermissions([
                ...user.permissions,
                ...user.roles,
              ]);
            },
            () => {}
          )
        )
      )
    )
  );
}
