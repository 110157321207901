import { FlatTreeControl } from '@angular/cdk/tree';
import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatSidenav } from '@angular/material/sidenav';
import { MatTreeFlatDataSource, MatTreeFlattener } from '@angular/material/tree';
import { NavigationEnd, Router } from '@angular/router';
import { NavigationItem, NavigationService } from '@core/navigation';
import { WallsType } from '@core/resources/enums';
import { WallListModel } from '@core/resources/models/wall';
import { takeWhile, tap } from 'rxjs';
import { WallNavigationService } from 'src/app/modules/wall/wall-sidebar/wall-navigation.service';

interface FlatNode {
  expandable: boolean;
  title: string;
  level: number;
  link: string;
  type: 'link' | 'group';
}

@Component({
  selector: 'sim-side-menu',
  templateUrl: './side-menu.component.html',
  styleUrls: ['./side-menu.component.scss'],
})
export class SideMenuComponent implements OnInit, OnDestroy {
  readonly vm$ = this.wallNavigationService.vm$;
  readonly menuItems$ = this.navigationService.menuItems$;

  readonly wallsType = WallsType;

  @ViewChild('sidenav') sidenav!: MatSidenav;

  treeControl: FlatTreeControl<FlatNode>;
  treeFlattener: MatTreeFlattener<NavigationItem, FlatNode>;
  dataSource: MatTreeFlatDataSource<NavigationItem, FlatNode, FlatNode>;

  selectedItemLink: string;

  private isActive: boolean;

  constructor(
    private readonly navigationService: NavigationService,
    private readonly wallNavigationService: WallNavigationService,
    private readonly router: Router
  ) {
    this.isActive = true;

    this.treeControl = new FlatTreeControl<FlatNode>(
      node => node.level,
      node => node.expandable
    );

    this.treeFlattener = new MatTreeFlattener(
      this._transformer,
      node => node.level,
      node => node.expandable,
      node => node.children
    );

    this.dataSource = new MatTreeFlatDataSource(this.treeControl, this.treeFlattener);
    this.selectedItemLink = this.router.url;
  }

  get isOpen(): boolean {
    return this.sidenav === undefined ? false : this.sidenav.opened;
  }

  ngOnInit(): void {
    this.menuItems$
      .pipe(
        tap(items => (this.dataSource.data = items)),
        takeWhile(() => this.navigationService.getState().isLoading)
      )
      .subscribe();

    this.router.events
      .pipe(
        takeWhile(() => this.isActive),
        tap(event => {
          if (event instanceof NavigationEnd) {
            this.selectedItemLink = event.url;
          }
        })
      )
      .subscribe();
  }

  ngOnDestroy(): void {
    this.isActive = false;
  }

  hasChild = (_: number, node: FlatNode): boolean => node.expandable;

  selectWall(wall: WallListModel | WallsType, sidenav: MatSidenav): void {
    this.wallNavigationService.updateLoadedWall(wall);
    this.toggleSideMenu();
  }

  toggleSideMenu(): void {
    this.sidenav.toggle();

    if (this.sidenav.opened) {
      this.treeControl.collapseAll();
      this.expandParent();
    }
  }

  // Will not work correctly, if group names are not unique
  private expandParent(): void {
    if (this.selectedItemLink === '' || this.selectedItemLink === '/wall') {
      return;
    }

    // find node that has selected item as a child
    let parent: NavigationItem | undefined = undefined;

    for (let node of this.dataSource.data) {
      let child = node.children?.find(c => c.link === this.selectedItemLink);

      if (child !== undefined) {
        parent = node;
        break;
      }
    }

    if (parent === undefined) {
      return;
    }

    // find parent in flattened nodes and expand it
    let flatParentNode = this.treeControl.dataNodes.find(
      flatNode => flatNode.title === parent?.title
    );

    if (flatParentNode === undefined) {
      return;
    }

    this.treeControl.expand(flatParentNode);
  }

  private _transformer = (node: NavigationItem, level: number) => {
    return {
      expandable: !!node.children && node.children.length > 0,
      title: node.title,
      level: level,
      link: node.link !== undefined ? node.link : '',
      type: node.type !== undefined ? node.type : 'group',
    };
  };
}
